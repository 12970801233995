import React from 'react';

import type { AccordionHeaderArgs } from '@customTypes/accordion';

import styles from './AccordionHeader.module.scss';

export function AccordionHeader({ title = '', subtitle = '' }: AccordionHeaderArgs) {
  return (
    <div className={styles.header} id={title}>
      {title && <p className={styles.title}>{title}</p>}
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    </div>
  );
}
