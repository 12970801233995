import React from 'react';

import { useProducts } from '@hooks/useProducts';

import Layout from '@components/Layouts/Layout';
import InformationBanner from '@components/Blocks/InformationBanner';
import ProductsBlock from '@components/Blocks/ProductsBlock';
import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';

export function Head() {
  return (
    <>
      <SEO
        title="Заказать фотокалендарь"
        description="Закажите календарь с собственными фотографиями в Периодика Пресс. Приятно дарить, подписывать и украшать интерьер."
      />
      <YandexTableau />
    </>
  );
}

function ProductsPage() {
  const { calendarProducts } = useProducts();
  return (
    <Layout>
      <InformationBanner
        banner={{
          title: 'Календари',
          description: ` <p>
          Соберите календарь с любимыми кадрами из путешествий, вдохновляющими цитатами или семейными снимками.
      </p>
      <br/>
      <p>
      Он будет уникальным, ведь его можно напечатать с любого месяца. Посвятите его личному году, годовщине свадьбы, отношений, дружбы или другой памятной дате.
      </p>
      <br/>
      <p>Пусть ценные снимки и воспоминания радуют вас и ваших близких круглый год.</p>
      <br/>
      <p>Закажите календарь сегодня и получите его в Москве и СПб через 3-4 рабочих дня. В других городах — от 4 рабочих дней.</p>
      </p>
      `,
          image: 'calendar-photo.jpg',
        }}
        twoCols
      />

      <ProductsBlock products={calendarProducts} />
    </Layout>
  );
}

export default ProductsPage;
