import React from 'react';

import { useProducts } from '@hooks/useProducts';

import Layout from '@components/Layouts/Layout';
import InformationBanner from '@components/Blocks/InformationBanner';
import ProductsBlock from '@components/Blocks/ProductsBlock';
import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';

export function Head() {
  return (
    <>
      <SEO
        title="Купить поздравительные открытки: красивые подарочные открытки с доставкой"
        description="Закажите уникальную поздравительную открытку для самых близких людей! Интересные дизайны от Периодики запомнятся и станут отличным дополнением к подарку!"
      />
      <YandexTableau />
    </>
  );
}

function ProductsPage() {
  const { postcardProducts } = useProducts();

  return (
    <Layout>
      <InformationBanner
        banner={{
          title: 'Подарочные открытки',
          description: ` <p>
          Дополните заказ открыткой с&nbsp;теплой и&nbsp;душевной иллюстрацией. Мы&nbsp;подготовили несколько дизайнов открыток, которые украсят любой подарок и&nbsp;станут полноценной его&nbsp;частью. Дарите близким приятные эмоции ко&nbsp;дню рождения, личной совместной дате или&nbsp;просто без&nbsp;повода.
          </p>
          `,
          image: 'new-postcard-banner.jpg',
          textColor: 'white',
        }}
        twoCols
      />
      <ProductsBlock products={postcardProducts} />
    </Layout>
  );
}

export default ProductsPage;
